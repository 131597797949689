export const API_URL = 'https://order.ellaapp.com/admin/web/v1/';
export const API_URL2 = 'https://order.ellaapp.com/admin/web/';
//export const LOCAL_PAYMENT_API_URL = "https://192.168.10.11";
export const LOCAL_PAYMENT_API_URL = "http://localhost";
export const LOCAL_PAYMENT_PORT = "5000";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const cart_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "http://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://nkiosk.ellaapp.com/kioskautologin/a4411c750fcae8c76307d18efa4a7b48/e9f53d74e2551154a6348ed013e3ef86";
export const page_redirection_time = 30;
export const apitimeout = 69000;
export const cancel_timeout = 10000;
export const enable_test_buttons = false;
export const payment_failed_modal = 30;
export const settlement_timer = 30;
export const terminalCheck = 10000;
export const terminal_check_enable = true;
export const menu_items_timer = 20000;
export const images_display_timer = 15000;
export const DOMAIN_URL = "https://nkiosk.ellaapp.com";
export const qr_page_redirection_time = 60;
export const qr_page_retry_modal_time = 15;
export const card_page_redirection_time = 70;
export const dbs_qr_timer = 120;
export const dbs_qr_timer_res_wait = 60;
export const jtc_payment_page_redirection_time = 61;
export const jtc_payment_page_apitimeout_time = 60000;
export const apiSigningKey = 'ad8ce5d5d98a1a5fd5cb772aeb5a93dc';
